import React from "react"
import styles from "./contact.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={[`contact`, `architectural services`, `tamil nadu`]} />
    <div
      style={{ width: `100%` }}
      className={`row d-flex justify-content-lg-between justify-content-sm-center ${
        styles.content
      }`}
    >
      <div className={"col-lg-5 col-md-12"}>
        <h3>Visit us</h3>
        <p>
          <strong>Address: </strong>
          27, Sai grace complex, 6th street, K.K Pudur, Saibaba Colony,
          Coimbatore, 641038
        </p>
        <p>
          <strong>Phone: </strong>+91-78457-67246
        </p>
        <p>
          <strong>Email: </strong>services.phoenixarchitecture@gmail.com
        </p>
      </div>
      <p className={`${styles.seperator} col-lg-2`}>- or -</p>
      <div className="col-lg-5 col-md-12">
        <h3>Drop us a message</h3>
        <ContactForm />
      </div>
    </div>
  </Layout>
)

export default ContactPage
