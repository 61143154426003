import React from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(280, "Too Long!")
    .required("Required"),
})

const ContactForm = () => {
  return (
    <Formik
      initialValues={{ name: "", email: "", message: "", submission: "" }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting }) => {
        values.submission = ""
        fetch("https://formcarry.com/s/YHcE1DV5K9s", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        })
          .then(response => {
            if (response.status === 200) {
              values.name = ""
              values.email = ""
              values.message = ""
              values.submission =
                "Message received! We will get back to you soon."
            } else {
              values.submission = "Problem sending messsage."
            }
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            values.submission = "There was some error!"
            setSubmitting(false)
          })
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <div className="form-group row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label col-form-label-sm"
            >
              Name
            </label>
            <div className="col-sm-9">
              <Field
                type="text"
                name="name"
                id="name"
                className={`form-control ${errors.name &&
                  touched.name &&
                  "is-invalid"}`}
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="email"
              className="col-sm-3 col-form-label col-form-label-sm"
            >
              Email
            </label>
            <div className="col-sm-9">
              <Field
                type="email"
                name="email"
                id="email"
                className={`form-control ${errors.email &&
                  touched.email &&
                  "is-invalid"}`}
                aria-describedby="emailHelp"
              />
              {errors.email && touched.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="message"
              className="col-sm-3 col-form-label col-form-label-sm"
            >
              Message
            </label>
            <div className="col-sm-9">
              <Field
                type="message"
                rows="5"
                maxLength="600"
                name="message"
                id="message"
                component="textarea"
                className={`form-control ${errors.message &&
                  touched.message &&
                  "is-invalid"}`}
              />
              {errors.message && touched.message && (
                <div className="invalid-feedback">{errors.message}</div>
              )}
            </div>
          </div>
          <div
            className={`text-center mb-2 ${
              values.submission ===
              "Message received! We will get back to you soon."
                ? "text-success"
                : "text-danger"
            }`}
          >
            <small>{values.submission}</small>
          </div>
          <button
            type="submit"
            className="mx-auto col-sm-12 btn btn-primary btn-block"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
